import React, { useState, useContext, useEffect } from "react";
import { Translation } from "react-i18next";
import i18next from "i18next";
import { api_patch, handleError } from "../../API.js";
import PersonContext from "../../PersonContext.js";
import { Loading } from "../Components.js";
import { Alert } from "../Alert.js";

export const Details = (props) => {
  let context = useContext(PersonContext);
  let [isLoading, setLoading] = useState(true);
  let [isSubmitting, setSubmitting] = useState(false);
  let [isInvalid, setInvalid] = useState(false);
  let [data, setData] = useState(false);

  useEffect(() => {
    setData({
      firstName: context.person.firstName,
      lastName: context.person.lastName,
      address1: context.person.address.address1,
      zip: context.person.address.zip,
      zipName: context.person.address.zipName,
      //country: context.person.address.country,
      email: context.personCommunication.email,
      mobilePhoneNumber: context.personCommunication.mobilePhoneNumber,
    });
    setLoading(false);
  }, []); // eslint-disable-line

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [showForm, setShowForm] = useState(false);

  const handleSubmit = () => {
    //console.log(data);

    let invalid = {};
    for (let keyName of Object.keys(data)) {
      if (typeof data[keyName] === "undefined" || data[keyName] === "") {
        invalid[keyName] = true;
      }
    }

    if (Object.keys(invalid).length) {
      Alert(i18next.t("Errors.FillAllFields"));
      setInvalid(invalid);
      return false;
    } else {
      setInvalid(false);
    }

    setSubmitting(true);
    api_patch("/persons/" + context.person.personId.externalId, data)
      .then((resp) => {
        context.setPersonData({
          person: resp.person,
          personCommunication: resp.personCommunication,
        });
        setSubmitting(false);
        Alert(i18next.t("Settings.DetailsUpdateSuccess"));
      })
      .catch((err) => {
        handleError(err);
        setSubmitting(false);
      });
  };

  return (
    <Translation>
      {(t) =>
        isLoading ? (
          <div className="d-flex justify-content-center mt-4 mb-4">
            <Loading>{t("Settings.LoadingDetails")}</Loading>
          </div>
        ) : (
          <div className={isSubmitting ? " form-disabled" : ""}>
            <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
              <h2 className="mb-0">{t("Settings.DetailsHeadline")}</h2>
              <div>
                <button
                  className="btn secondary-btn color-primary size-small"
                  onClick={() => {
                    setShowForm(!showForm);
                  }}
                >
                  {showForm ? "Tillbaka" : t("ChangeSubscription.Change")}
                </button>
              </div>
            </div>
            <div className="border-card">
            <ul className="list medium">
              <li>
                <div>
                  <div>
                    <span className="body-text-small color-rock">
                      {t("MemberInfo.Name")}
                    </span>
                  </div>
                  <div className="text-end">
                    <span className="body-text-small color-black">
                      {context.person.firstName} {context.person.lastName}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div>
                    <span className="body-text-small color-rock">
                      {t("MemberInfo.MemberId")}
                    </span>
                  </div>
                  <div className="text-end">
                    <span className="body-text-small color-black">
                      {context.person.personId.center}
                      {context.person.personId.id}
                    </span>
                  </div>
                </div>
              </li>
              {context.person.address.address1 && (
              <li>
                <div>
                  <div>
                    <span className="body-text-small color-rock">
                      {t("MemberInfo.Address")}
                    </span>
                  </div>
                  <div className="text-end">
                    <span className="body-text-small color-black">
                    {context.person.address.address1}

                    </span>
                  </div>
                </div>
              </li>
              )} 
              {context.person.address.zip && (
              <li>
                <div>
                  <div>
                    <span className="body-text-small color-rock">
                    {t("Settings.DetailsZip")}
                    </span>
                  </div>
                  <div className="text-end">
                    <span className="body-text-small color-black">
                    {context.person.address.zip}
                    </span>
                  </div>
                </div>
              </li>
              )}      
              {context.personCommunication.email && (
              <li>
                <div>
                  <div>
                    <span className="body-text-small color-rock">
                    {t("MemberInfo.Email")}
                    </span>
                  </div>
                  <div className="text-end">
                    <span className="body-text-small color-black">
                    {context.personCommunication.email}
                    </span>
                  </div>
                </div>
              </li>
              )}
              {context.personCommunication.mobilePhoneNumber && (
              <li>
                <div>
                  <div>
                    <span className="body-text-small color-rock">
                    {t("MemberInfo.Phone")}
                    </span>
                  </div>
                  <div className="text-end">
                    <span className="body-text-small color-black">
               
                  {context.personCommunication.mobilePhoneNumber}
                    </span>
                  </div>
                </div>
              </li>
              )}

        
            </ul>
           </div>
            {showForm ? (
            <div className="form-holder">
            <div>
              <div className="input-group">
                <label className="d-none">
                  {t("Settings.DetailsFirstName")}
                </label>
                <input
                  type="text"
                  className={isInvalid.firstName ? "invalid" : ""}
                  name="firstName"
                  onChange={handleChange}
                  value={data.firstName}
                  placeholder={t("Settings.DetailsFirstName")}
                />
              </div>
            </div>

            <div>
              <div className="input-group">
                <label className="d-none">
                  {t("Settings.DetailsLastName")}
                </label>
                <input
                  type="text"
                  className={isInvalid.lastName ? "invalid" : ""}
                  name="lastName"
                  onChange={handleChange}
                  value={data.lastName}
                  placeholder={t("Settings.DetailsLastName")}
                />
              </div>
            </div>

            <div>
              <div className="input-group">
                <label className="d-none">{t("Settings.DetailsAddress")}</label>
                <input
                  type="text"
                  className={isInvalid.address1 ? "invalid" : ""}
                  name="address1"
                  disabled={isSubmitting ? true : false}
                  onChange={handleChange}
                  value={data.address1}
                  placeholder={t("Settings.DetailsAddress")}
                />
              </div>
            </div>

            <div>
              <div className="input-group">
                <label className="d-none">{t("Settings.DetailsZip")}</label>
                <input
                  type="text"
                  className={isInvalid.zip ? "invalid" : ""}
                  name="zip"
                  disabled={isSubmitting ? true : false}
                  onChange={handleChange}
                  value={data.zip}
                  placeholder={t("Settings.DetailsZip")}
                />
              </div>
            </div>

            <div>
              <div className="input-group">
                <label className="d-none">{t("Settings.DetailsZipName")}</label>
                <input
                  type="text"
                  className={isInvalid.zipName ? "invalid" : ""}
                  name="zipName"
                  disabled={isSubmitting ? true : false}
                  onChange={handleChange}
                  value={data.zipName}
                  placeholder={t("Settings.DetailsZipName")}
                />
              </div>
            </div>

            <div>
              <div className="input-group">
                <label className="d-none">{t("Settings.DetailsEmail")}</label>
                <input
                  type="text"
                  className={isInvalid.email ? "invalid" : ""}
                  name="email"
                  disabled={isSubmitting ? true : false}
                  onChange={handleChange}
                  value={data.email}
                  placeholder={t("Settings.DetailsEmail")}
                />
              </div>
            </div>

            <div>
              <div className="input-group">
                <label className="d-none">{t("Settings.DetailsPhone")}</label>
                <input
                  type="text"
                  className={isInvalid.mobilePhoneNumber ? "invalid" : ""}
                  name="mobilePhoneNumber"
                  disabled={isSubmitting ? true : false}
                  onChange={handleChange}
                  value={data.mobilePhoneNumber}
                  placeholder={t("Settings.DetailsPhone")}
                />
              </div>
            </div>

            <div className="col-12 mt-small mb-small">
              <div className="text-center">
                <button
                  className="btn secondary-btn color-secondary"
                  onClick={handleSubmit}
                  disabled={isSubmitting ? true : false}
                >
                  {t("Settings.Save")}
                </button>
              </div>
            </div>
          </div>
            ) : null}
            </div>
          )
        }
      </Translation>
    );
  }
