import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PersonContext from "../../PersonContext.js";
import { Translation } from "react-i18next";
import { SmallList } from "../SmallList.js";
import { SmallListItem } from "../SmallListItem.js";
import { numberFormat } from "../../lib/numberFormat";

export const Subscription = ({ subscription }) => {
  const context = useContext(PersonContext);
  const { t } = useTranslation();
  return (
    <div className="wrapper">
      <ul className="list medium mb-5">
        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("Subscriptions.PersonType")}{" "}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">
                {t("PersonTypeLabels." + context.person.personType)}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("MemberInfo.Center")}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">{context.center.name}</span>
            </div>
          </div>
        </li>

        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("Subscriptions.AccessLevel")}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small">
                {t(context.subscription.cardArea)}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("MemberInfo.Center")}
              </span>
            </div>
            <div className="text-end">
              <span className="body-text-small color-black">
                {context.center.name}
              </span>
            </div>
          </div>
        </li>
        {context.subscription &&
        typeof context.subscription.subscriptionVersion !== "undefined" &&
        typeof context.subscription.subscriptionVersion == 1 && // eslint-disable-line
        typeof context.subscription.product !== "undefined" ? (
          <li>
            <div>
              <div>
                <span className="body-text-small color-rock">
                  {t("MemberInfo.Membership")}
                </span>
              </div>
              <div className="text-end">
                <span className="body-text-small color-black">
                  {context.subscription.product.name}
                </span>
              </div>
            </div>
          </li>
        ) : (
          ""
        )}
        {context.hasMyactic && context.myactic ? (
          <li>
            <div>
              <div>
                <span className="body-text-small color-rock">
                  {t("MyActic.MemberLevel")}
                </span>
              </div>
              <div className="text-end">
                <span className="body-text-small color-black">
                  {context.myactic.loyaltyLevelName || ""}
                </span>
              </div>
            </div>
          </li>
        ) : null}

        <li>
          <div>
            <div>
              <span className="body-text-small color-rock">
                {t("MemberInfo.Membership")}
              </span>
            </div>
            <div className="text-end d-flex flex-row justify-content-end">
      {context.subscription.cardIncludes.map((feature, index) => {
        return (
          <span className="body-text-small" key={index}>
            {feature}
            {index < context.subscription.cardIncludes.length - 1 && <span className="me-1">, </span>}
          </span>
        );
      })}
    </div>
          </div>
        </li>
      </ul>
      {context.subscription.product.type === "CASH" ? null : (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="body-text-small color-rock">
              {t("ChangeSubscription.PeriodPrice")}
            </span>
          </div>
          <div>
            <Price price={context.subscription.price} />
          </div>
        </div>
      )}
    </div>
  );
};

const Price = ({ price }) => {
  var countrycode = "NO";

  if (countrycode === "NO") {
    return (
      <span>
        <span className="h4">{Math.round(price)}</span>
        <span className="h4">kr/mnd</span>
      </span>
    );
  } else if (countrycode === "DE" || countrycode === "FI") {
    return (
      <span>
        <span className="h4">{numberFormat(price, 2, ".", "")}</span>
        <span className="h4">&euro;/Mon</span>
      </span>
    );
  } else {
    // SE
    return (
      <span>
        <span className="h4">{Math.round(price)}</span>
        <span className="h4">kr/mån</span>
      </span>
    );
  }
};
